import { __decorate } from "tslib";
// Watch
import { Component, Vue } from 'vue-property-decorator';
import { pixStore, userToolStore, mainStore, plugnotasNfseServicoStore, plugnotasConfiguracaoStore, } from '@/store';
import _ from 'lodash';
import PeriodSelectorComponent from '@/components/PeriodSelectorComponent.vue';
import ClickableCard from '@/components/ClickableCard.vue';
import PixIntegradorExibirDetalhesPixDialog from '@/components/PixIntegradorExibirDetalhesPixDialog.vue';
import PlugnotasConsultaEmiteButton from '@/components/PlugnotasConsultaEmiteButton.vue';
import OpenNewTabComponent from '@/components/OpenNewTabComponent.vue';
import ImprimirTabelaComponent from '@/components/ImprimirTabelaComponent.vue';
let ConciliacaoBancaria = class ConciliacaoBancaria extends Vue {
    constructor() {
        super(...arguments);
        this.porCategoriaHeaders = [
            { text: 'Categoria', value: 'financeiro_categoria_nome' },
            { text: 'Valor', value: 'valor' },
            { text: 'Quantidade', value: 'quantidade' },
        ];
        this.porOrigemHeaders = [
            { text: 'Origem', value: 'origem' },
            { text: 'Valor', value: 'valor' },
            { text: 'Quantidade', value: 'quantidade' },
        ];
        this.porConciliacaoHeaders = [
            { text: 'Conciliado', value: 'origem' },
            { text: 'Valor', value: 'valor' },
            { text: 'Quantidade', value: 'quantidade' },
        ];
        this.loading = false;
        this.search = '';
        this.configuracaoIntegracao = [];
        this.selectPeriodDate = null;
        this.items = [];
        this.nfseServicosCadastradosItems = null;
        this.plugnotasConfiguracao = null;
        this.tabelaRef = null;
    }
    get headers() {
        const array = [
            { text: 'Banco', value: 'banco_nome' },
            { text: 'Recebido em', value: 'horario' },
            { text: 'Valor Recebido', value: 'valor', aggregate_function: 'sum' },
            { text: 'Origem', value: 'origem' },
            { text: 'PIX - E2E', value: 'e2e_id' },
            { text: 'PIX - TXID', value: 'txid' },
            { text: 'PIX - Info. Pagador', value: 'pagador_nome' },
            { text: 'Parcela - ID', value: 'financeiro_parcela_id' },
            { text: 'Entidade - Nome', value: 'entidade_nome' },
            { text: 'Entidade - Documento', value: 'entidade_documento' },
            { text: 'Financeiro - Parcela', value: 'financeiro_parcela' },
            { text: 'Financeiro - Categoria', value: 'financeiro_categoria_nome' },
        ];
        // @ts-ignore
        // if (
        //   this.plugnotasConfiguracao &&
        //   this.plugnotasConfiguracao.aceite_user_id
        // ) {
        //   array.push({ text: 'NF', value: 'plugnotas_id' });
        // }
        return array;
    }
    get porConciliacaoItems() {
        if (this.items) {
            return _(this.items)
                .groupBy((i) => !!i.financeiro_parcela_id)
                .map((item, id) => {
                const qntdObj = _.countBy(item, (i) => !!i.financeiro_parcela_id);
                const quantidade = Object.values(qntdObj)[0];
                return {
                    origem: id,
                    valor: _.sumBy(item, 'valor'),
                    quantidade,
                };
            })
                .value();
        }
    }
    get porOrigemItems() {
        if (this.items) {
            return _(this.items)
                .groupBy('origem')
                .map((item, id) => {
                const qntdObj = _.countBy(item, 'origem');
                const quantidade = Object.values(qntdObj)[0];
                return {
                    origem: id,
                    valor: _.sumBy(item, 'valor'),
                    quantidade,
                };
            })
                .value();
        }
    }
    get porCategoriaItems() {
        if (this.items) {
            return _(this.items)
                .groupBy('financeiro_categoria_nome')
                .map((item, id) => {
                const qntdObj = _.countBy(item, 'financeiro_categoria_nome');
                const quantidade = Object.values(qntdObj)[0];
                return {
                    financeiro_categoria_nome: id,
                    valor: _.sumBy(item, 'valor'),
                    quantidade,
                };
            })
                .value();
        }
    }
    get allowView() {
        if (userToolStore.userToolsIndexed[`Conciliação bancária`]) {
            return userToolStore.userToolsIndexed[`Conciliação bancária`][0]
                .allow_view;
        }
    }
    get allowCreate() {
        if (userToolStore.userToolsIndexed[`Conciliação bancária`]) {
            return userToolStore.userToolsIndexed[`Conciliação bancária`][0]
                .allow_create;
        }
    }
    get allowEdit() {
        if (userToolStore.userToolsIndexed[`Conciliação bancária`]) {
            return userToolStore.userToolsIndexed[`Conciliação bancária`][0]
                .allow_edit;
        }
    }
    get allowDelete() {
        if (userToolStore.userToolsIndexed[`Conciliação bancária`]) {
            return userToolStore.userToolsIndexed[`Conciliação bancária`][0]
                .allow_delete;
        }
    }
    get userProfile() {
        return mainStore.userProfile;
    }
    allowNF(financeiroCategoriaId) {
        return _.some(this.nfseServicosCadastradosItems, (i) => i.financeiro_categoria_id == financeiroCategoriaId);
    }
    async showPixDetails(item) {
        // @ts-ignore
        this.$refs.PixIntegradorExibirDetalhesPixDialog.show(item.company_configuracao_integracao_bancaria_id, item.txid, item.e2e_id);
    }
    getCompanyId() {
        return (parseInt(this.$route.params.id, 0) || mainStore.userProfile.company.id);
    }
    async buscar() {
        this.loading = true;
        this.items = await pixStore.pixListarRecebimentos({
            data_inicio: this.selectPeriodDate[0],
            data_fim: this.selectPeriodDate[1],
            curso: false,
            material_didatico: false,
            conciliacao_status_id: 1,
            tipo_pesquisa: 'R',
        });
        if (!this.nfseServicosCadastradosItems) {
            this.nfseServicosCadastradosItems =
                await plugnotasNfseServicoStore.getPlugnotasNfseServicosByCompanyId(this.getCompanyId());
        }
        this.loading = false;
    }
    async mounted() {
        this.$nextTick(() => {
            this.tabelaRef = this.$refs.tabela;
        });
        this.plugnotasConfiguracao =
            await plugnotasConfiguracaoStore.getPlugnotasConfiguracaoMe();
    }
};
ConciliacaoBancaria = __decorate([
    Component({
        components: {
            ClickableCard,
            PeriodSelectorComponent,
            PixIntegradorExibirDetalhesPixDialog,
            PlugnotasConsultaEmiteButton,
            OpenNewTabComponent,
            ImprimirTabelaComponent,
        },
    })
], ConciliacaoBancaria);
export default ConciliacaoBancaria;
