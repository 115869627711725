import { __decorate } from "tslib";
import { Component, Vue, Watch, Prop } from 'vue-property-decorator';
import { userActionStore, utilsStore } from '@/store';
import _ from 'lodash';
let ImprimirTabelaComponent = class ImprimirTabelaComponent extends Vue {
    constructor() {
        super(...arguments);
        this.dialogImprimirTabela = false;
        this.pdfOptions = false;
        this.selected = 2;
        this.refs = [];
    }
    onChangePorpRef(item) {
        this.refs = item;
    }
    get permiteExportarDados() {
        return userActionStore.permiteExportarDados;
    }
    async imprimirTabela() {
        const modo = this.selected;
        const Arrayitems = [];
        const Arrayheaders = [];
        _.forEach(this.refs, (ref) => {
            if (ref) {
                const items = [];
                const headers = [];
                const html = ref.$el.innerHTML;
                const parser = new DOMParser();
                const doc = parser.parseFromString(html, 'text/html');
                const headersRows = Array.from(doc.querySelectorAll('thead tr th')).filter((header) => header.innerText.trim() !== '');
                const headersFilterRow = doc.getElementById('tr-filter');
                if (headersFilterRow) {
                    headersFilterRow.remove();
                }
                const rows = doc.querySelectorAll('tbody tr');
                const tempItems = [];
                const tempHeaders = [];
                let headersLen = 0;
                headersRows.forEach((item) => {
                    const headerText = item.innerText.trim();
                    if (headerText) {
                        tempHeaders.push(headerText);
                        headersLen++;
                    }
                });
                rows.forEach((row, index) => {
                    if (row.style.display === 'none')
                        return;
                    const rowItems = [];
                    const cells = Array.from(row.querySelectorAll('td'));
                    if (cells[0] &&
                        (cells[0].innerHTML.includes('v-input--checkbox') ||
                            cells[0].innerHTML.includes('v-input--selection-controls') ||
                            cells[0].innerText.trim() === '')) {
                        cells.shift();
                    }
                    const isIndexCell = cells[0] && cells[0].innerText.trim().match(/^\d+$/);
                    if (isIndexCell) {
                        cells.shift();
                    }
                    cells.forEach((cell) => {
                        let cellText = cell.innerText.trim();
                        if (cellText ||
                            (cellText === '' && cell.innerHTML !== '') ||
                            !cell.innerHTML.includes('<!---->')) {
                            if (modo == 0 || modo == 1) {
                                rowItems.push(cell.innerHTML.replace(/<button.*<\/button>/, ''));
                            }
                            else {
                                if (cellText.search(/\d\.\d{3},/gm)) {
                                    cellText = cellText.replace('.', '').replace(',', '.');
                                }
                                rowItems.push(cellText);
                            }
                        }
                    });
                    if (ref.propExpandable) {
                        const expandedData = ref.items[index];
                        if (expandedData && typeof expandedData === 'object') {
                            for (const key in expandedData) {
                                if (expandedData.hasOwnProperty(key) &&
                                    !rowItems.includes(expandedData[key])) {
                                    rowItems.push(expandedData[key]);
                                }
                            }
                        }
                    }
                    if (rowItems.length >= headersLen) {
                        tempItems.push(rowItems);
                    }
                });
                for (let idx = 0; idx < tempHeaders.length; idx += headersLen) {
                    headers.push(tempHeaders.slice(idx, idx + headersLen));
                }
                Arrayitems.push(tempItems);
                Arrayheaders.push(headers);
            }
        });
        if (modo == 0 || modo == 1) {
            const html2 = await utilsStore.utilsImprimirTabela({
                payload: {
                    title: this.propTitle,
                    headers: Arrayheaders,
                    table_items: Arrayitems,
                    orientacao: modo,
                    titles: this.propTitulosImprimir,
                },
            });
            if (html2) {
                const w = window.open();
                w.document.write(html2);
                setTimeout(() => {
                    w.print();
                }, 1000);
            }
        }
        else {
            const csvArray = [];
            _.forEach(Arrayheaders, (headers) => {
                csvArray.push([headers]);
                _.forEach(Arrayitems[Arrayheaders.indexOf(headers)], (items) => {
                    csvArray.push([items]);
                });
                csvArray.push([]);
            });
            const csvContent = 'data:text/csv;charset=utf-8,' +
                csvArray.map((e) => e.join(',')).join('\n');
            const encodedUri = encodeURI(csvContent);
            window.open(encodedUri);
        }
    }
};
__decorate([
    Prop()
], ImprimirTabelaComponent.prototype, "propRef", void 0);
__decorate([
    Prop({ type: Boolean, default: false })
], ImprimirTabelaComponent.prototype, "propDashboardFinanceiro", void 0);
__decorate([
    Prop({ type: String, default: '' })
], ImprimirTabelaComponent.prototype, "propTitle", void 0);
__decorate([
    Prop({ type: Array, default: null })
], ImprimirTabelaComponent.prototype, "propTitulosImprimir", void 0);
__decorate([
    Watch('propRef')
], ImprimirTabelaComponent.prototype, "onChangePorpRef", null);
ImprimirTabelaComponent = __decorate([
    Component
], ImprimirTabelaComponent);
export default ImprimirTabelaComponent;
